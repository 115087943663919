import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Fader from "../helper/Fader";
import { Link } from "gatsby";

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout className="page-home-container">
        <SEO title="404" />
        <Fader>
          <section className="home-body">
            <h1 className="h0">
              404 error, this page is not available,{" "}
              <Link to="/">return to site</Link>
            </h1>
          </section>
        </Fader>
      </Layout>
    );
  }
}

export default NotFoundPage;
